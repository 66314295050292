




















import { Vue, Component, Model, Prop } from "vue-property-decorator";
import { ChainIdType } from "@/constants";
import { CurrencyType } from "@/components/misc/CurrencySelect/types";

@Component
export default class ChainInput extends Vue {
  @Model("change", { type: String }) readonly formType!: CurrencyType;
  @Prop({ default: false }) disabled!: boolean;

  set(val: ChainIdType) {
    if (this.disabled) return;
    this.$emit("change", val);
  }
  transfer() {
    this.$router.replace("/wallet/cross_chain");
  }

  get wallet() {
    return this.$store.state.activeWallet;
  }

  get isEVMSupported() {
    return this.wallet.ethAddress;
  }
}
