



































































































































import { Vue, Component, Prop } from "vue-property-decorator";

import SelectMintUTXO from "@/components/wallet/studio/mint/SelectMintUtxo/SelectMintUTXO.vue";
import UrlForm from "@/components/wallet/studio/mint/forms/UrlForm.vue";
import Utf8Form from "@/components/wallet/studio/mint/forms/Utf8Form.vue";
import JsonForm from "@/components/wallet/studio/mint/forms/JsonForm.vue";
import GenericForm from "@/components/wallet/studio/mint/forms/GenericForm.vue";
import NftPayloadView from "@/components/misc/NftPayloadView/NftPayloadView.vue";

import {
  NFTMintOutput,
  NFTTransferOutput,
  UTXO,
} from "avalanche/dist/apis/avm";
import { NftFamilyDict } from "@/store/modules/assets/types";
import { avm, bintools, pChain } from "@/AVA";
import {
  GenericFormType,
  JsonFormType,
  NftMintFormType,
  UrlFormType,
  UtfFormType,
} from "@/components/wallet/studio/mint/types";
import {
  PayloadBase,
  URLPayload,
  UTF8Payload,
  JSONPayload,
} from "avalanche/dist/utils";
import Big from "big.js";
import { bnToBig } from "@/helpers/helper";
import NftFamilyCardsPreview from "@/components/misc/NftFamilyCardsPreview.vue";
import NftCard from "@/components/wallet/portfolio/NftCard.vue";

type NftType = "utf8" | "url" | "json";

@Component({
  components: {
    NftCard,
    NftFamilyCardsPreview,
    GenericForm,
    SelectMintUTXO,
    UrlForm,
    NftPayloadView,
    Utf8Form,
    JsonForm,
  },
})
export default class MintNft extends Vue {
  @Prop() mintUtxo!: UTXO;

  quantity = 1;
  nftType: NftType = "url";
  nftFormType = "generic";
  payloadPreview: null | PayloadBase = null;
  canSubmit = false;
  isSuccess = false;
  isLoading = false;
  txId = "";

  maxPreviewUtxoLen = 18;
  get typeDescription() {
    if (this.nftFormType === "generic") {
      return this.$t("studio.mint.type_col.typeDesc.generic");
    }

    if (this.nftType === "url") {
      return this.$t("studio.mint.type_col.typeDesc.url");
    } else if (this.nftType === "json") {
      return this.$t("studio.mint.type_col.typeDesc.json");
    } else {
      return this.$t("studio.mint.type_col.typeDesc.utf8");
    }
  }

  get nftFamsDict(): NftFamilyDict {
    return this.$store.state.Assets.nftFamsDict;
  }

  get family() {
    let idBuff = this.mintUtxo.getAssetID();
    let id = bintools.cb58Encode(idBuff);
    return this.nftFamsDict[id];
  }

  get groupId() {
    return (this.mintUtxo.getOutput() as NFTMintOutput).getGroupID();
  }

  get formComponent() {
    switch (this.nftType) {
      case "utf8":
        return Utf8Form;
      case "url":
        return UrlForm;
      case "json":
        return JsonForm;
      default:
        return Utf8Form;
    }
  }

  get payloadContent() {
    if (!this.payloadPreview) return null;
    return this.payloadPreview.getContent().toString();
  }

  get nftTitle() {
    try {
      let json = JSON.parse(this.payloadContent || "");
      return json.avalanche.title;
    } catch (err) {
      return "";
    }
  }

  get nftDesc() {
    try {
      let json = JSON.parse(this.payloadContent || "");
      return json.avalanche.desc;
    } catch (err) {
      return "";
    }
  }

  clearUtxo() {
    this.$emit("clearUtxo");
  }

  get txFee(): Big {
    return bnToBig(avm.getTxFee(), 9);
  }

  onInput(form: NftMintFormType | null) {
    if (form === null) {
      this.payloadPreview = null;
      this.canSubmit = false;
      return;
    }

    try {
      let payload;
      if (this.nftFormType === "generic") {
        // let dataStr = JSON.stringify((form as GenericFormType).data)
        // payload = new JSONPayload(dataStr)
        payload = new JSONPayload((form as GenericFormType).data);
      } else {
        switch (this.nftType) {
          case "url":
            payload = new URLPayload((form as UrlFormType).url);
            break;
          case "json":
            payload = new JSONPayload((form as JsonFormType).data);
            break;
          case "utf8":
            payload = new UTF8Payload((form as UtfFormType).text);
            break;
          default:
            payload = new UTF8Payload("hi there");
            break;
        }
      }

      this.payloadPreview = payload;
      this.canSubmit = true;
    } catch (e) {
      console.error(e);
    }
  }

  get familyUtxos(): UTXO[] {
    let dict = this.$store.getters["Assets/walletNftDict"];
    // return this.$store.getters.walletNftDict[this.family.id] || []
    return dict[this.family.id] || [];
  }

  get groupUtxos() {
    let utxos = this.familyUtxos;
    let ids: number[] = [];

    let filtered = utxos.filter((utxo) => {
      let groupId = (utxo.getOutput() as NFTTransferOutput).getGroupID();

      if (ids.includes(groupId)) {
        return false;
      } else {
        ids.push(groupId);
        return true;
      }
    });

    // order by group id
    filtered.sort((a, b) => {
      let gA = (a.getOutput() as NFTTransferOutput).getGroupID();
      let gB = (b.getOutput() as NFTTransferOutput).getGroupID();
      return gA - gB;
    });

    return filtered.slice(0, this.maxPreviewUtxoLen);
  }

  async submit() {
    let wallet = this.$store.state.activeWallet;
    if (!wallet) return;

    this.isLoading = true;

    try {
      let txId = await wallet.mintNft(
        this.mintUtxo,
        this.payloadPreview,
        this.quantity
      );
      this.onSuccess(txId);
    } catch (e) {
      console.error(e);
    }
  }

  cancel() {
    this.$emit("cancel");
  }

  onSuccess(txId: string) {
    this.isLoading = false;
    this.isSuccess = true;
    this.txId = txId;

    this.$store.dispatch("Notifications/add", {
      type: "success",
      title: "Success",
      message: "Collectible minted and added to your wallet.",
    });

    setTimeout(() => {
      this.$store.dispatch("Assets/updateUTXOs");
      this.$store.dispatch("History/updateTransactionHistory");
    }, 2000);
  }

  onError(err: any) {
    this.isLoading = false;
  }
}
