


















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { NFTTransferOutput, UTXO } from "avalanche/dist/apis/avm";
import NftPayloadView from "@/components/misc/NftPayloadView/NftPayloadView.vue";
import { getPayloadFromUTXO } from "@/helpers/helper";
import { bintools } from "@/AVA";
import { AvaNftFamily } from "@/js/AvaNftFamily";
import { IGroupQuantity } from "@/components/wallet/studio/mint/types";

@Component({
  components: {
    NftPayloadView,
  },
})
export default class NftListItem extends Vue {
  @Prop() sample!: UTXO;
  @Prop({ default: false }) disabled!: boolean;

  quantity = 1;

  @Watch("quantity")
  onQuantitChange(val: number) {
    if (val < 1) {
      this.quantity = 1;
      return;
    }
  }

  @Watch("quantity")
  onQuantityChange(val: number) {
    let max = this.allUtxos.length;

    if (val > max) {
      this.quantity = max;
    }

    this.emit();
  }

  emit() {
    let msg: IGroupQuantity = {
      id: `${this.assetId}_${this.groupId}`,
      utxos: this.selectedUtxos,
    };
    this.$emit("change", msg);
  }

  get assetId() {
    let famId = this.sample.getAssetID();
    return bintools.cb58Encode(famId);
  }

  get selectedUtxos() {
    return this.allUtxos.slice(0, this.quantity);
  }

  get payload() {
    return getPayloadFromUTXO(this.sample);
  }

  get groupId() {
    return (this.sample.getOutput() as NFTTransferOutput).getGroupID();
  }

  get allUtxos() {
    let famId = this.sample.getAssetID();
    // let utxos: UTXO[] = this.$store.getters.walletNftDict[bintools.cb58Encode(famId)]
    let utxos: UTXO[] = this.$store.getters["Assets/walletNftDict"][
      bintools.cb58Encode(famId)
    ];

    let filtered = utxos.filter((utxo) => {
      let gId = (utxo.getOutput() as NFTTransferOutput).getGroupID();

      if (gId === this.groupId) {
        return true;
      }
      return false;
    });
    return filtered;
  }

  remove() {
    this.$emit("remove", this.sample);
  }

  mounted() {
    this.emit();
  }
}
