

























import { Vue, Component, Prop } from "vue-property-decorator";
import { AvaNftFamily } from "@/js/AvaNftFamily";
import NFTCard from "./NftCard.vue";
import { IWalletNftDict, IWalletNftMintDict } from "@/store/types";
import {
  NFTTransferOutput,
  UTXO,
  AVMConstants,
  NFTMintOutput,
} from "avalanche/dist/apis/avm";
import { NftGroupDict } from "@/components/wallet/portfolio/types";
import CollectibleFamilyGroup from "@/components/wallet/portfolio/CollectibleFamilyGroup.vue";
@Component({
  components: {
    NFTCard,
    CollectibleFamilyGroup,
  },
})
export default class CollectibleFamilyRow extends Vue {
  @Prop() family!: AvaNftFamily;

  // get groups() {}
  get nftDict(): IWalletNftDict {
    // return this.$store.getters.walletNftDict
    return this.$store.getters["Assets/walletNftDict"];
  }

  get nftMintDict(): IWalletNftMintDict {
    // return this.$store.getters.walletNftMintDict
    return this.$store.getters["Assets/nftMintDict"];
  }

  get utxos(): UTXO[] {
    return this.nftDict[this.family.id] || [];
  }

  get mintUtxos(): UTXO[] {
    return this.nftMintDict[this.family.id] || [];
  }

  get canMint() {
    return this.mintUtxos.length > 0;
  }

  get groupDict(): NftGroupDict {
    let dict: NftGroupDict = {};
    for (var i = 0; i < this.utxos.length; i++) {
      let utxo = this.utxos[i];
      let out = utxo.getOutput() as NFTTransferOutput;
      let groupId = out.getGroupID();

      let target = dict[groupId];
      if (target) {
        target.push(utxo);
      } else {
        dict[groupId] = [utxo];
      }
    }
    return dict;
  }
  get allUtxos(): UTXO[] {
    return this.utxos.concat(this.mintUtxos);
  }

  get mintUrl() {
    if (this.mintUtxos.length === 0) return "";
    let mintUtxo = this.mintUtxos[0];

    return `/wallet/studio?utxo=${mintUtxo.getUTXOID()}`;
  }

  get groupIds(): number[] {
    let ids: number[] = this.allUtxos.map((val) => {
      let id = val.getOutput().getOutputID();
      if (id === AVMConstants.NFTMINTOUTPUTID) {
        let out = val.getOutput() as NFTMintOutput;
        return out.getGroupID();
      } else {
        let out = val.getOutput() as NFTTransferOutput;
        return out.getGroupID();
      }
    });

    let idsUnique = ids.filter((val, index) => {
      return ids.indexOf(val) === index;
    });

    idsUnique.sort((a, b) => {
      return a - b;
    });

    return idsUnique;
  }
}
