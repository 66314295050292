












































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import { PayloadTypes, PayloadBase } from "avalanche/dist/utils";

const payloadtypes = PayloadTypes.getInstance();

import Tooltip from "@/components/misc/Tooltip.vue";
import NftPayloadView from "@/components/misc/NftPayloadView/NftPayloadView.vue";
import NFTViewModal from "@/components/modals/NFTViewModal.vue";
import { UTXO } from "avalanche/dist/apis/avm";
@Component({
  components: { NFTViewModal, NftPayloadView, Tooltip },
})
export default class NftCard extends Vue {
  @Prop() payload!: PayloadBase;
  @Prop({ default: 1 }) quantity!: number;
  @Prop() groupID!: number;
  @Prop() utxo?: UTXO;

  $refs!: {
    modal: NFTViewModal;
  };

  transfer(ev: MouseEvent) {
    ev.stopPropagation();
    if (!this.utxo) return;

    let utxoId = this.utxo.getUTXOID();
    this.$router.push({
      path: "/wallet/transfer",
      query: {
        nft: utxoId,
        chain: "V",
      },
    });
  }

  expand() {
    this.$refs.modal.open();
  }

  get payloadTypeID() {
    return this.payload.typeID();
  }

  get payloadTypeName() {
    return payloadtypes.lookupType(this.payloadTypeID) || "Unknown Type";
  }

  get payloadContent() {
    return this.payload.getContent().toString();
  }

  get nftTitle() {
    try {
      let json = JSON.parse(this.payloadContent);
      return json.avalanche.title;
    } catch (err) {
      return "";
    }
  }

  get nftDesc() {
    try {
      let json = JSON.parse(this.payloadContent);
      return json.avalanche.desc;
    } catch (err) {
      return "";
    }
  }
}
