





















































import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";
import Tooltip from "@/components/misc/Tooltip.vue";
import ERC721Token from "@/js/ERC721Token";
import ERC721View from "@/components/misc/ERC721View.vue";
import ERC721ViewModal from "@/components/modals/ERC721ViewModal.vue";
@Component({
  components: { ERC721ViewModal, ERC721View, Tooltip },
})
export default class ERC721Card extends Vue {
  @Prop() index!: string;
  @Prop() token!: ERC721Token;

  $refs!: {
    view_modal: ERC721ViewModal;
  };

  metadata: any = "";
  isRaw = false;

  mounted() {
    this.getData();
  }

  get img() {
    let data = this.metadata;
    if (!data) return null;
    return data.img || data.image || null;
  }

  get name() {
    return this.metadata?.name;
  }

  get description() {
    return this.metadata?.description;
  }

  async getData() {
    try {
      let uri = await this.token.getTokenURI(parseInt(this.index));
      this.metadata = (await axios.get(uri)).data;
    } catch (e) {
      this.metadata = null;
    }
  }

  transfer(ev: any) {
    ev.stopPropagation();
    this.$router.push({
      path: "/wallet/transfer",
      query: {
        chain: "U",
        token: this.token.contractAddress,
        tokenId: this.index,
      },
    });
  }
  expand() {
    this.$refs.view_modal.open();
  }

  toggleRaw() {
    this.isRaw = !this.isRaw;
  }
}
