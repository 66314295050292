









import { Component, Vue } from "vue-property-decorator";
import { JsonFormType } from "@/components/wallet/studio/mint/types";

// const JSONEditor = require('jsoneditor')

@Component
export default class JsonForm extends Vue {
  data = "{\n\n}";

  get isValid(): boolean {
    let data = this.data;

    if (data.length === 0) return false;
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  mounted() {
    // const container = this.$refs.editor
    // const options = {
    //     mode: 'text',
    // }
    // const editor = new JSONEditor(container, options)
    //
    // console.log(editor)
  }
  onInput() {
    let msg: null | JsonFormType = null;

    if (this.isValid) {
      msg = {
        data: this.data,
      };
    } else {
      msg = null;
    }

    this.$emit("onInput", msg);
  }
}
