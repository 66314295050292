





















import { Vue, Component, Prop } from "vue-property-decorator";
import ERC721Token from "@/js/ERC721Token";
import { WalletType } from "@/js/wallets/types";
import ERC721View from "@/components/wallet/portfolio/ERC721Card.vue";
import { ERC721WalletBalance } from "@/store/modules/assets/modules/types";
@Component({
  components: { ERC721View },
})
export default class ERC721FamilyRow extends Vue {
  @Prop() family!: ERC721Token;

  get walletBalance(): string[] {
    return (
      this.$store.state.Assets.ERC721.walletBalance[
        this.family.contractAddress
      ] || []
    );
  }

  get hasBalance() {
    return this.walletBalance.length > 0;
  }
}
