



















import { Vue, Component, Prop } from "vue-property-decorator";
import Erc20Token from "@/js/Erc20Token";

@Component
export default class ERC20Row extends Vue {
  @Prop() token!: Erc20Token;

  get balText() {
    return this.token.balanceBig.toLocaleString();
  }

  get isBalance() {
    return !this.token.balanceBN.isZero();
  }

  get sendLink() {
    return `/wallet/transfer?chain=C&token=${this.token.data.address}`;
  }
}
