















































































import { Vue, Component, Watch } from "vue-property-decorator";
import { BN } from "avalanche";
import { pChain } from "@/AVA";
import { bnToBig } from "@/helpers/helper";
import Big from "big.js";

@Component
export default class NewCollectibleFamily extends Vue {
  name: string = "";
  symbol: string = "";
  groupNum = 1;
  isLoading = false;
  isSuccess = false;
  error = "";
  txId = "";

  @Watch("symbol")
  onSymbolChange(val: string) {
    let newVal = val.toUpperCase();
    // Remove numbers
    newVal = newVal.replace(/[0-9]/g, "");
    this.symbol = newVal;
  }

  get txFee(): Big {
    return bnToBig(pChain.getCreationTxFee(), 9);
  }

  validate(): boolean {
    if (this.symbol.length === 0) {
      this.error = "You must provide a symbol.";
      return false;
    } else if (this.symbol.length > 4) {
      this.error = "Symbol must be 4 characters max.";
      return false;
    } else if (this.groupNum < 1) {
      this.error = "Number of groups must be at least 1.";
      return false;
    }
    return true;
  }
  async submit() {
    if (!this.validate()) {
      return;
    }
    let wallet = this.$store.state.activeWallet;
    if (!wallet) return;

    this.error = "";
    this.isLoading = true;

    let nameTrimmed = this.name.trim();
    let symbolTrimmed = this.symbol.trim();

    try {
      let txId = await wallet.createNftFamily(
        nameTrimmed,
        symbolTrimmed,
        this.groupNum
      );
      console.log(txId);
      this.onSuccess(txId);
    } catch (e) {
      this.onError(e);
    }
  }

  cancel() {
    this.$emit("cancel");
  }

  onError(e: any) {
    this.error = e;
    console.error(e);
    this.isLoading = false;
  }

  onSuccess(txId: string) {
    this.isLoading = false;
    this.isSuccess = true;
    this.txId = txId;

    this.$store.dispatch("Notifications/add", {
      type: "success",
      title: "Success",
      message: "Collectible family created.",
    });

    setTimeout(() => {
      this.$store.dispatch("Assets/updateUTXOs");
      this.$store.dispatch("History/updateTransactionHistory");
    }, 3000);
  }

  get mintUtxos() {
    // return this.$store.getters.walletNftMintUTXOs
    return this.$store.state.Assets.nftMintUTXOs;
  }
}
