













































import { Vue, Component } from "vue-property-decorator";
import { iUserAccountEncrypted } from "@/store/types";
import SaveAccountModal from "@/components/modals/SaveAccount/SaveAccountModal.vue";
import AccountSettingsModal from "@/components/modals/AccountSettings/AccountSettingsModal.vue";
import { WalletType } from "@/js/wallets/types";
import ImageDayNight from "@/components/misc/ImageDayNight.vue";

@Component({
  components: {
    AccountSettingsModal,
    SaveAccountModal,
    ImageDayNight,
  },
})
export default class AccountMenu extends Vue {
  $refs!: {
    save_modal: SaveAccountModal;
    settings_modal: AccountSettingsModal;
  };

  get account(): iUserAccountEncrypted | null {
    return this.$store.getters["Accounts/account"];
  }

  get wallet(): WalletType | null {
    return this.$store.state.activeWallet;
  }

  get isLedger() {
    let w = this.wallet;
    if (!w) return false;
    return w.type === "ledger";
  }

  openSettings() {
    this.$refs.settings_modal.open();
  }

  save() {
    this.$refs.save_modal.open();
  }
}
