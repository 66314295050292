





























import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import AvaAsset from "../../../js/AvaAsset";
import Hexagon from "@/components/misc/Hexagon.vue";
import { BN } from "avalanche";
import { bnToBig } from "../../../helpers/helper";
import { priceDict } from "../../../store/types";
import { WalletType } from "@/js/wallets/types";

import Big from "big.js";

@Component({
  components: {
    Hexagon,
  },
})
export default class FungibleRow extends Vue {
  @Prop() asset!: AvaAsset;

  get iconUrl(): string | null {
    if (!this.asset) return null;

    if (this.isDjtxToken) {
      return "/img/djtx_icon_circle.svg";
    }

    return null;
  }

  get isBalance(): boolean {
    if (!this.asset) return false;
    if (!this.amount.isZero()) {
      return true;
    }
    return false;
  }

  get totalUSD(): Big {
    if (!this.isDjtxToken) return Big(0);
    let usdPrice = this.priceDict.usd;
    let bigAmt = bnToBig(this.amount, this.asset.denomination);
    let usdBig = bigAmt.times(usdPrice);
    return usdBig;
  }

  get priceDict(): priceDict {
    return this.$store.state.prices;
  }

  get sendLink(): string {
    if (!this.asset) return `/wallet/transfer`;
    return `/wallet/transfer?asset=${this.asset.id}&chain=X`;
  }

  get djtxToken(): AvaAsset {
    return this.$store.getters["Assets/AssetAVA"];
  }

  get isDjtxToken(): boolean {
    if (!this.asset) return false;

    if (this.djtxToken.id === this.asset.id) {
      return true;
    } else {
      return false;
    }
  }

  get name(): string {
    let name = this.asset.name;
    // TODO: Remove this hack after network change
    if (name === "AVA") return "DJT";
    return name;
  }

  get symbol(): string {
    let sym = this.asset.symbol;

    // TODO: Remove this hack after network change
    if (sym === "AVA") return "DJT";
    return sym;
  }

  get amount() {
    let amt = this.asset.getTotalAmount();
    return amt.add(this.evmDjtxBalance);
  }

  get amtBig() {
    return bnToBig(this.amount, this.asset.denomination);
  }

  get evmDjtxBalance(): BN {
    let wallet: WalletType | null = this.$store.state.activeWallet;

    if (!this.isDjtxToken || !wallet) {
      return new BN(0);
    }
    // Convert to 9 decimal places
    let bal = wallet.ethBalance;
    let balRnd = bal.divRound(new BN(Math.pow(10, 9).toString()));
    return balRnd;
  }
}
